var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container is-fluid"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"form"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Member number'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 1288',"disabled":"","type":"number"},model:{value:(_vm.objObservationResidency.residency_id),callback:function ($$v) {_vm.$set(_vm.objObservationResidency, "residency_id", $$v)},expression:"objObservationResidency.residency_id"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Date'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objObservationResidency.date),callback:function ($$v) {_vm.$set(_vm.objObservationResidency, "date", $$v)},expression:"objObservationResidency.date"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Remarks'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"maxlength":"200","type":"textarea","placeholder":_vm.$t('Ex:') + ' Correo enviado,tipo de notificacion...'},model:{value:(_vm.objObservationResidency.observation),callback:function ($$v) {_vm.$set(_vm.objObservationResidency, "observation", $$v)},expression:"objObservationResidency.observation"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"buttons"},[_c('b-button',{staticClass:"button btn-save",attrs:{"loading":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.confirmation)}}},[_vm._v("Guardar")]),_c('b-button',{staticClass:"button btn-cancel",on:{"click":_vm.close}},[_vm._v("Cancelar")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }